import styled from "styled-components"
import { media } from "@styles/breakpoints"

export const PapercraftImages = styled.div`
  height: 100%;
  position: relative;
  width: 100%; 

  &:after {
    content: "";
    display: block;
    padding-top: calc((7 / 6) * 100%);
    width: 100%;
  }

  ${media.sm`
     &:after {
        padding-top: calc((6 / 5) * 100%);
     }
  `}
`

export const PapercraftImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: ${props => 
  props.fullWidth
  ? "100%"
  : "90%"};
  height: 100%;
  margin: auto;
  

  .gatsby-image-wrapper {
    position: absolute!important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: ${props =>
    props.smLift
    ? "translateY(-5%)"
    : props.mdLift
    ? "translateY(-7%)"
    : props.lgLift
    ? "translateY(-10%)"
    : "initial"
    };
    
  }
`
