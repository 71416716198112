import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { PapercraftImages, PapercraftImage } from "@atoms/papercraft"

const PapercraftCheckouts = () => {
  const images = useRef()
  const shadow = useRef()
  const person = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.defaults({
      ease: "sine.inOut",
    })

    const intro = gsap.timeline({
      scrollTrigger: {
        trigger: images.current,
        start: "top bottom",
        end: "top center",
        scrub: 1,
        // once: true,
      },
    })
    intro
      .addLabel("start")
      .from(".phones", { scale: 0.5, opacity: 0, stagger: 0.25 }, "start")

    const tl = gsap.timeline({
      repeat: -1,
      yoyo: true,
      paused: true,
    })
    tl.addLabel("start")
      .to(
        shadow.current,
        {
          opacity: "-=0.5",
          duration: 4,
        },
        "start"
      )
      .to(
        person.current,
        {
          y: "-=2%",
          duration: 4,
        },
        "start"
      )
      .from(
        ".phones",
        {
          y: "random(+=10, +=30, 5)",
          stagger: 0.1,
          duration: 4,
        },
        "start"
      )

    ScrollTrigger.create({
      trigger: images.current,
      onEnter: () => {
        tl.play()
      },
      onEnterBack: () => {
        tl.play()
      },
      onLeave: () => {
        tl.pause()
      },
      onLeaveBack: () => {
        tl.pause()
      },
    })
  }, [])

  const width = 800
  const quality = 90

  return (
    <PapercraftImages ref={images}>
      <PapercraftImage smLift ref={shadow}>
        <StaticImage
          src="../../../assets/visuals/papercraft/checkouts/checkouts--shadow.png"
          alt="Checkouts papercraft floating woman's shadow"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage
        smLift
        className="phones"
        alt="Checkouts papercraft first collection of floating completed checkouts"
      >
        <StaticImage
          src="../../../assets/visuals/papercraft/checkouts/checkouts--phones-1.png"
          alt="Checkouts papercraft second collection of floating completed checkouts"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage smLift className="phones">
        <StaticImage
          src="../../../assets/visuals/papercraft/checkouts/checkouts--phones-2.png"
          alt="Checkouts papercraft third collection of floating completed checkouts"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage smLift className="phones">
        <StaticImage
          src="../../../assets/visuals/papercraft/checkouts/checkouts--phones-3.png"
          alt="Checkouts papercraft fourth collection of floating completed checkouts"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage smLift className="phones">
        <StaticImage
          src="../../../assets/visuals/papercraft/checkouts/checkouts--phones-4.png"
          alt="Checkouts papercraft fifth and final collection of floating completed checkouts"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={person}>
        <StaticImage
          src="../../../assets/visuals/papercraft/checkouts/checkouts--person.png"
          alt="Checkouts papercraft floating woman using computer to setup checkouts"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
    </PapercraftImages>
  )
}

export default PapercraftCheckouts
