import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { PapercraftImages, PapercraftImage } from "@atoms/papercraft"

const PapercraftBilling = () => {
  const images = useRef()
  const shadow = useRef()
  const bill = useRef()
  const person = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.defaults({
      ease: "sine.inOut",
    })

    const tl = gsap.timeline({
      repeat: -1,
      yoyo: true,
      paused: true,
    })

    tl.addLabel("start")
    tl.to(
      shadow.current,
      {
        opacity: "-=0.5",
        duration: 4,
      },
      "start"
    )
    tl.to(
      bill.current,
      {
        y: "-=3%",
        delay: 0.25,
        duration: 4,
      },
      "start"
    )
    tl.to(
      person.current,
      {
        y: "-=2%",
        duration: 4,
      },
      "start"
    )

    ScrollTrigger.create({
      trigger: images.current,
      onEnter: () => {
        tl.play()
      },
      onEnterBack: () => {
        tl.play()
      },
      onLeave: () => {
        tl.pause()
      },
      onLeaveBack: () => {
        tl.pause()
      },
    })
  }, [])

  const width = 800
  const quality = 90

  return (
    <PapercraftImages ref={images}>
      <PapercraftImage smLift ref={shadow}>
        <StaticImage
          src="../../../assets/visuals/papercraft/billing/shadows.png"
          alt="Billing papercraft shadow of floating dollar bill"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={bill}>
        <StaticImage
          src="../../../assets/visuals/papercraft/billing/bill.png"
          alt="Billing papercraft floating dollar bill"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={person}>
        <StaticImage
          src="../../../assets/visuals/papercraft/billing/person.png"
          alt="Billing papercraft floating woman above dollar bill"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
    </PapercraftImages>
  )
}

export default PapercraftBilling
