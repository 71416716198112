import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { PapercraftImages, PapercraftImage } from "@atoms/papercraft"

const PapercraftBackOffice = () => {
  const images = useRef()
  const shadowComputer = useRef()
  const computer = useRef()
  const shadowPerson = useRef()
  const person = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.defaults({
      ease: "sine.inOut",
    })

    const tl = gsap.timeline({
      repeat: -1,
      yoyo: true,
      paused: true,
    })

    tl.addLabel("")
    tl.to(
      shadowComputer.current,
      {
        opacity: "-=0.5",
        duration: 4,
      },
      "start"
    )
    tl.to(
      shadowPerson.current,
      {
        opacity: "-=0.5",
        duration: 4,
      },
      "start"
    )
    tl.to(
      person.current,
      {
        y: "-=4%",
        duration: 4,
      },
      "start"
    )
    tl.to(
      computer.current,
      {
        y: "-=2%",
        duration: 4,
        delay: 0.5,
      },
      "start"
    )

    ScrollTrigger.create({
      trigger: images.current,
      onEnter: () => {
        tl.play()
      },
      onEnterBack: () => {
        tl.play()
      },
      onLeave: () => {
        tl.pause()
      },
      onLeaveBack: () => {
        tl.pause()
      },
    })
  }, [])

  const width = 800
  const quality = 90

  return (
    <PapercraftImages ref={images}>
      <PapercraftImage ref={shadowComputer}>
        <StaticImage
          src="../../../assets/visuals/papercraft/back-office/shadow-computer.png"
          alt="Back-office papercraft floating computer shadow"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage ref={computer}>
        <StaticImage
          src="../../../assets/visuals/papercraft/back-office/computer.png"
          alt="Back-office papercraft floating computer with dashboard"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage ref={shadowPerson}>
        <StaticImage
          src="../../../assets/visuals/papercraft/back-office/shadow-person.png"
          alt="Back-office papercraft floating man's shadow"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage ref={person}>
        <StaticImage
          src="../../../assets/visuals/papercraft/back-office/person.png"
          alt="Back-office papercraft floating man using computer"
          width={width}
          quality={quality}
          placeholder="none"
        />
      </PapercraftImage>
    </PapercraftImages>
  )
}

export default PapercraftBackOffice
