import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { PapercraftImages, PapercraftImage } from "@atoms/papercraft"

const PapercraftAutomation = () => {
  const images = useRef()
  const shadow = useRef()
  const person = useRef()

  const armOne = useRef()
  const armTwo = useRef()
  const armThree = useRef()
  const armFour = useRef()

  const envelope = useRef()
  const card = useRef()
  const calendar = useRef()
  const phone = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.defaults({
        ease: "sine.inOut",
      })
    }
    const intro = gsap.timeline({
      scrollTrigger: {
        trigger: images.current,
        start: "top bottom",
        end: "top center",
        scrub: 1,
        // once: true,
      },
    })
    intro
      .addLabel("start")
      .from(armOne.current, { rotate: "-15%" }, "start")
      .from(armTwo.current, { rotate: "-10%" }, "start")
      .from(armThree.current, { rotate: "10%" }, "start")
      .from(armFour.current, { rotate: "15%" }, "start")
      .from(envelope.current, { rotate: "-15%" }, "start")
      .from(card.current, { rotate: "-10%" }, "start")
      .from(calendar.current, { rotate: "10%" }, "start")
      .from(phone.current, { rotate: "15%" }, "start")

    const tl = gsap.timeline({
      repeat: -1,
      yoyo: true,
      paused: true,
    })
    tl.addLabel("start")
      .to(
        shadow.current,
        {
          opacity: "-=0.5",
          duration: 4,
        },
        "start"
      )
      .to(
        person.current,
        {
          y: "-=2%",
          duration: 4,
        },
        "start"
      )
      .to(
        armOne.current,
        {
          y: "-=4%",
          duration: 4,
        },
        "start"
      )
      .to(
        armTwo.current,
        {
          y: "-=2.5%",
          duration: 4,
        },
        "start"
      )
      .to(
        armThree.current,
        {
          y: "-=2.5%",
          duration: 4,
        },
        "start"
      )
      .to(
        armFour.current,
        {
          y: "-=4%",
          duration: 4,
        },
        "start"
      )
      .to(
        ".automation-item",
        {
          y: "-=5%",
          duration: 4,
        },
        "start"
      )
      .to(
        ".automation-item--inner",
        {
          y: "-=3%",
          duration: 4,
        },
        "start"
      )

    ScrollTrigger.create({
      trigger: images.current,
      onEnter: () => {
        tl.play()
      },
      onEnterBack: () => {
        tl.play()
      },
      onLeave: () => {
        tl.pause()
      },
      onLeaveBack: () => {
        tl.pause()
      },
    })
  }, [])

  const width = 800
  const quality = 90

  return (
    <PapercraftImages ref={images}>
      <PapercraftImage smLift ref={shadow}>
        <StaticImage
          src="../../../assets/visuals/papercraft/automation/shadow.png"
          width={width}
          alt="Automation papercraft persons shadow"
          placeholder="none"
          quality={quality}
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={person}>
        <StaticImage
          src="../../../assets/visuals/papercraft/automation/person.png"
          width={width}
          alt="Automation papercraft man with controller"
          placeholder="none"
          quality={quality}
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={armOne}>
        <StaticImage
          src="../../../assets/visuals/papercraft/automation/arm-1.png"
          width={width}
          alt="Automation papercraft robotic arm on far left"
          placeholder="none"
          quality={quality}
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={armTwo}>
        <StaticImage
          src="../../../assets/visuals/papercraft/automation/arm-2.png"
          width={width}
          alt="Automation papercraft robotic arm in center left"
          placeholder="none"
          quality={quality}
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={armThree}>
        <StaticImage
          src="../../../assets/visuals/papercraft/automation/arm-3.png"
          width={width}
          alt="Automation papercraft robotic arm in center right"
          placeholder="none"
          quality={quality}
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={armFour}>
        <StaticImage
          src="../../../assets/visuals/papercraft/automation/arm-4.png"
          width={width}
          alt="Automation papercraft robotic arm in far right"
          placeholder="none"
          quality={quality}
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={envelope} className="automation-item">
        <StaticImage
          src="../../../assets/visuals/papercraft/automation/arm-1__envelope.png"
          width={width}
          alt="Automation papercraft floating envelope"
          placeholder="none"
          quality={quality}
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={card} className="automation-item--inner">
        <StaticImage
          src="../../../assets/visuals/papercraft/automation/arm-2__card.png"
          width={width}
          alt="Automation papercraft floating credit card"
          placeholder="none"
          quality={quality}
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={calendar} className="automation-item--inner">
        <StaticImage
          src="../../../assets/visuals/papercraft/automation/arm-3_calendar.png"
          width={width}
          alt="Automation papercraft floating calendar"
          placeholder="none"
          quality={quality}
        />
      </PapercraftImage>
      <PapercraftImage smLift ref={phone} className="automation-item">
        <StaticImage
          src="../../../assets/visuals/papercraft/automation/arm-4_phone.png"
          width={width}
          alt="Automation papercraft floating phone"
          placeholder="none"
          quality={quality}
        />
      </PapercraftImage>
    </PapercraftImages>
  )
}

export default PapercraftAutomation
