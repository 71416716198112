import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { Heading, Paragraph } from "@atoms/typography"
import { Section, Container, Flex, Column } from "@atoms/layout"

const Security = ({ block }) => {
  return (
    <Section lg lgTop light={block.background}>
      {block.title && (
        <Container sm paragraph containerSmall>
          <Heading html="h6" level="sh" strong center lightGray>
            Security
          </Heading>
          <Heading html="h2" level="h2" center>
            {block.title}
          </Heading>
        </Container>
      )}
      <Container>
        <Flex justifyCenter>
          <Item third>
            <Icon>
              <GatsbyImage
                image={block.oneIcon.gatsbyImageData}
                alt={block.oneIcon.alt}
                title={block.oneIcon.title}
              />
            </Icon>
            <Content>
              <Heading level="">{block.oneTitle}</Heading>
              <Paragraph
                level="md"
                dangerouslySetInnerHTML={{ __html: block.oneContent }}
              />
            </Content>
          </Item>
          <Item third>
            <Icon>
              <GatsbyImage
                image={block.twoIcon.gatsbyImageData}
                alt={block.twoIcon.alt}
                title={block.twoIcon.title}
              />
            </Icon>
            <Content>
              <Heading level="">{block.twoTitle}</Heading>
              <Paragraph
                level="md"
                dangerouslySetInnerHTML={{ __html: block.twoContent }}
              />
            </Content>
          </Item>
          <Item third>
            <Icon>
              <GatsbyImage
                image={block.threeIcon.gatsbyImageData}
                alt={block.threeIcon.alt}
                title={block.threeIcon.title}
              />
            </Icon>
            <Content>
              <Heading level="">{block.threeTitle}</Heading>
              <Paragraph
                level="md"
                dangerouslySetInnerHTML={{ __html: block.threeContent }}
              />
            </Content>
          </Item>
          <Item third>
            <Icon>
              <GatsbyImage
                image={block.fourIcon.gatsbyImageData}
                alt={block.fourIcon.alt}
                title={block.fourIcon.title}
              />
            </Icon>
            <Content>
              <Heading level="">{block.fourTitle}</Heading>
              <Paragraph
                level="md"
                dangerouslySetInnerHTML={{ __html: block.fourContent }}
              />
            </Content>
          </Item>
          <Item third>
            <Icon>
              <GatsbyImage
                image={block.fiveIcon.gatsbyImageData}
                alt={block.fiveIcon.alt}
                title={block.fiveIcon.title}
              />
            </Icon>
            <Content>
              <Heading level="">{block.fiveTitle}</Heading>
              <Paragraph
                level="md"
                dangerouslySetInnerHTML={{ __html: block.fiveContent }}
              />
            </Content>
          </Item>
        </Flex>
      </Container>
    </Section>
  )
}

export default Security

export const blockSecurity = graphql`
  fragment BlockSecurity on DatoCmsBlockSecurity {
    id
    title
    background
    oneIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    oneTitle
    oneContent
    twoIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    twoTitle
    twoContent
    threeIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    threeTitle
    threeContent
    fourIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    fourTitle
    fourContent
    fiveIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    fiveTitle
    fiveContent
    model {
      apiKey
    }
  }
`

const Item = styled(Column)`
  display: flex;
  margin-bottom: 2rem;
`

const Content = styled.div`
  flex: 1 1 100%;
  margin-left: 2rem;
`

const Icon = styled.div`
  margin: 0 auto var(--spacing--xxs);
  max-width: 112px;
  flex: 1 1 112px;
`
